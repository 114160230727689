<template>
  <div>
    <Row :gutter="8" class="m-b-10">

      <i-col span="4">
        <DatePicker placeholder="入账开始结束时间" size="small" v-model="dateRage" type="daterange" split-panels
          style="width:100%" :editable="false" clearable @on-change="onChangeDate"></DatePicker>
      </i-col>

      <i-col span="4">
        <Select v-model="query.incomeType" clearable size="small" placeholder="收支类型">
          <Option v-for="item in lifeIncomeTypeArray" :key="'incomeType_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>

      <i-col span="4">
        <Select v-model="query.feeItemType" clearable filterable size="small" placeholder="服务类型">
          <Option v-for="item in lifeFeeItemTypeArray" :key="'feeItem_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select v-model="settlementCompanyId" @on-change="onChangeCompany" clearable filterable size="small" placeholder="结算主体">
          <Option v-for="item in companyArray" :key="'selCom_' + item.settlementCompanyId" :value="item.settlementType+'_'+item.settlementCompanyId">{{ item.settlementCompanyName }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input v-model="query.keyWord" size="small" placeholder="关键字（名称、业务单号）"></i-input>
      </i-col>
      <i-col span="4">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
        <i-button size="small" class="m-r-5" type="success" icon="ios-search" @click="onExport">导出Excel</i-button>
      </i-col>

    </Row>
    <Row>
      <i-col span="24">
          <div
            v-for="(item, index) in listContentCategory"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked( item.serialNumber)"
            :class="
              checkContentCategorys.includes(item.serialNumber)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.moduleName }}
          </div>
        </i-col>
    </Row>
    <!-- <Row :gutter="8" class="m-b-10">
      <i-col span="12" class="text-center">
        <Card>
          <div class="p-b-10 p-t-10">
            <h2>核销总数量</h2>
          </div>
          <div class="text-orange">
            <h1>{{ formartNumber(quantity) }}</h1>
          </div>
        </Card>

      </i-col>
      <i-col span="12" class="text-center">
        <Card>
          <div class="p-b-10 p-t-10">
            <h2>核销总金额</h2>
          </div>
          <div class="money">
            <h1>{{ formartMoney(amount) }}</h1>
          </div>
        </Card>

      </i-col>
    </Row> -->
    <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { toMoney, toNumber } from '@/utils/wnumb_own'

import { listLifeBillDetail, listLifeSettlementCompany, listLifeIncomeType, listLifeFeeItemType, getModuleList } from '@/api/statement/lifebill'
export default {
  props: {
  },
  data () {
    return {
      listContentCategory: [],
      checkContentCategorys: [1],

      settlementCompanyId: '',
      companyArray: [],
      lifeIncomeTypeArray: [],
      lifeFeeItemTypeArray: [],
      salerArray: [],
      dateRage: [],
      amount: 0, // 核销总金额
      quantity: 0, // 核销总数量
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyWord: ''
      },
      list: [],
      total: 0,
      tableLoading: false,
      tableColumns: []
    }
  },
  created () {
    // this.query.publisherId = this.$store.getters.token.userInfo.publisherId=
    this.initModuleList()
    this.getCompanyData()
    this.getLifeFeeItemTypeData()
    this.getLifeIncomeTypeData()
    this.getTableData()
  },
  methods: {
  /**
     * 结算单详情显示字段分类接口
     */
    initModuleList () {
      getModuleList({ }).then(res => {
        if (res && !res.errcode) {
          this.listContentCategory = res

          this.initColumns()
        }
      })
    },

    initColumns () {
      const that = this
      that.tableColumns = []
      const tableColumnArray = {

        feeItemTypeName: { title: '类型', key: 'feeItemTypeName', minWidth: 60, align: 'center' },
        settlementCompanyName: { title: '结算主体', key: 'settlementCompanyName', minWidth: 60, align: 'center' },
        chargeTime: { title: '入账时间', key: 'chargeTime', minWidth: 60, align: 'center' },
        goodNo: { title: '业务单号', key: 'goodNo', minWidth: 60, align: 'center' },
        goodName: { title: '名称(商品、媒体)', key: 'goodName', minWidth: 60, align: 'center' },
        incomeTypeName: { title: '收支类型', key: 'incomeTypeName', minWidth: 60, align: 'center' },
        price: {
          title: '单价(元)',
          key: 'price',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.price)
            )
          }
        },
        quantity: {
          title: '数量',
          key: 'quantity',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h('span', {}, toNumber(row.quantity))
          }
        },
        verifyAmount: {
          title: '完成金额(元)',
          key: 'verifyAmount',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.verifyAmount)
            )
          }
        },
        chargeModeDesc: { title: '计价方式', key: 'chargeModeDesc', minWidth: 60, align: 'center' },
        actualAmount: {
          title: '金额(元)',
          key: 'actualAmount',
          minWidth: 60,
          align: 'center',
          render (h, { row }) {
            return h(
              'span',
              {
                style: { color: '#44bd32', 'font-weight': 'bold' }
              },
              toMoney(row.actualAmount)
            )
          }
        },

        // 订单
        orderStatusName: { title: '订单状态', key: 'orderStatusName', minWidth: 60, align: 'center' },
        orderTypeName: { title: '订单类型', key: 'orderTypeName', minWidth: 60, align: 'center' },
        expressTypeName: { title: '订单配送方式', key: 'expressTypeName', minWidth: 60, align: 'center' },
        orderCreateTime: { title: '订单创建时间', key: 'orderCreateTime', minWidth: 60, align: 'center' },
        orderPayTime: { title: '订单付款时间', key: 'orderPayTime', minWidth: 60, align: 'center' },
        orderSuccessTime: { title: '交易成功时间', key: 'orderSuccessTime', minWidth: 60, align: 'center' },

        // 支付信息
        payment: {
          title: '订单应收金额',
          key: 'payment',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.payment)
              )
            ])
          }
        },
        realPayment: {
          title: '订单实付金额',
          key: 'realPayment',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.realPayment)
              )
            ])
          }
        },
        postFee: {
          title: '运费',
          key: 'postFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.postFee)
              )
            ])
          }
        },
        transaction: { title: '支付流水号', key: 'transaction', minWidth: 60, align: 'center' },

        // 核销信息
        verifyStateName: {
          title: '核销状态',
          key: 'verifyStateName',
          minWidth: 60,
          align: 'center'
        },
        verifyTime: {
          title: '核销时间',
          key: 'verifyTime',
          minWidth: 60,
          align: 'center'
        },

        // 商品信息
        itemId: {
          title: '商品id',
          key: 'itemId',
          minWidth: 60,
          align: 'center'
        },
        expressStateName: {
          title: '订单商品状态',
          key: 'expressStateName',
          minWidth: 60,
          align: 'center'
        },
        itemNum: {
          title: '商品数量',
          key: 'itemNum',
          minWidth: 60,
          align: 'center'
        },
        discountPrice: { title: '商品单价', key: 'discountPrice', minWidth: 60, align: 'center' },
        itemActualAmount: { title: '商品实际成交金额', key: 'itemActualAmount', minWidth: 60, align: 'center' },

        // 收货信息
        receiverName: {
          title: '收货人/提货人',
          key: 'receiverName',
          minWidth: 60,
          align: 'center'
        },

        receiverPhone: {
          title: '收货人/提货人手机号',
          key: 'receiverPhone',
          minWidth: 60,
          align: 'center'
        },
        receiverProvince: {
          title: '收货人省份',
          key: 'receiverProvince',
          minWidth: 60,
          align: 'center'
        },
        receiverCity: {
          title: '收货人城市',
          key: 'receiverCity',
          minWidth: 60,
          align: 'center'
        },
        receiverDetailAddress: {
          title: '收货人详细',
          key: 'receiverDetailAddress',
          minWidth: 60,
          align: 'center'
        },

        // 商品退款
        refundFee: {
          title: '商品已退款金额',
          key: 'refundFee',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.receivedAmount)
              )
            ])
          }
        },
        itemRefundStatus: { title: '商品退款状态', key: 'itemRefundStatus', minWidth: 60, align: 'center' },

        // 合同信息
        contractTypeName: { title: '合同类型', key: 'contractTypeName', minWidth: 60, align: 'center' },
        groundCompanyName: { title: '地推', key: 'groundCompanyName', minWidth: 60, align: 'center' },
        businessName: { title: '商家名称', key: 'businessName', minWidth: 60, align: 'center' },
        productUnit: { title: '产品单位', key: 'productUnit', minWidth: 60, align: 'center' }
      }

      if (!that.checkContentCategorys.length) {
        that.checkContentCategorys = [1]
      }

      that.checkContentCategorys.sort((a, b) => (a - b)).forEach(item => {
        const category = that.listContentCategory.filter(x => parseInt(x.serialNumber) === parseInt(item))[0]
        if (category && category.fieldList.length) {
          category.fieldList.forEach(row => {
            // 验证当前全部列是否和数据库对应
            if (tableColumnArray[row.field]) {
              that.tableColumns.push(tableColumnArray[row.field])
            }
          })
        }
      })

      // this.tableColumns.push(tableColumnArray.operate)
    },
    // 变更多选框选择
    changeChecked (value) {
      const that = this
      if (that.checkContentCategorys.includes(value)) {
        const index1 = that.checkContentCategorys.indexOf(value)
        if (index1 > -1) {
          that.checkContentCategorys.splice(index1, 1)
        }
      } else {
        that.checkContentCategorys.push(value)
      }
      that.initColumns()
    },

    getTableData () {
      this.tableLoading = true
      listLifeBillDetail(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },
    onChangeCompany () {
      if (this.settlementCompanyId) {
        const keys = this.settlementCompanyId.split('_')
        this.query.settlementType = keys[0]
        this.query.settlementCompanyId = keys[1]
      } else {
        this.query.settlementType = null
        this.query.settlementCompanyId = null
      }
    },
    /**
     * 获取结算主体
     */
    getCompanyData () {
      this.companyArray = []
      listLifeSettlementCompany({ }).then(res => {
        if (res && !res.errcode) {
          this.companyArray = res
        }
      })
    },
    /**
     * 获取收支类型
     */
    getLifeIncomeTypeData () {
      this.lifeIncomeTypeArray = []
      listLifeIncomeType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeIncomeTypeArray = res
        }
      })
    },
    /**
     * 获取费用类型
     */
    getLifeFeeItemTypeData () {
      this.lifeFeeItemTypeArray = []
      listLifeFeeItemType({ }).then(res => {
        if (res && !res.errcode) {
          this.lifeFeeItemTypeArray = res
        }
      })
    },

    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },

    formartMoney (value) {
      return toMoney(value)
    },
    formartNumber (value) {
      return toNumber(value)
    },
    onExport () {
      const postData = {
        selectColumns: JSON.stringify(this.checkContentCategorys),
        startDate: this.query.startDate,
        endDate: this.query.endDate,
        feeItemType: this.query.feeItemType,
        incomeType: this.query.incomeType,
        keyWord: this.query.keyWord

      }
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/lifeBill/exportLifeBillDetail', postData, '账单明细（' + (new Date().getTime()) + '）.xlsx')
    }
  }
}
</script>
